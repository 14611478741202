'use strict';
import QuantityTable from './quantity-table/quantity-table';

jQuery( document ).ready( function ( $ ) {
	let tables = $( document ).find( '.ywdpd-table-discounts-wrapper' );
	tables.each( function () {
		new QuantityTable( $( this ) );
	} );
	$( document.body ).on( 'payment_method_selected', function ( e ) {
		let selectedPaymentMethod = $(
			'.woocommerce-checkout input[name="payment_method"]:checked'
		).attr( 'id' );
		let data = {
			action: ywdpd_popup_args.actions.valid_rule_in_checkout,
			security: ywdpd_popup_args.nonces.valid_rule_in_checkout,
			payment_method: selectedPaymentMethod,
			context: 'frontend',
		};
		jQuery.ajax( {
			type: 'POST',
			url: ywdpd_popup_args.ajax_url,
			data,
			dataType: 'json',
			beforeSend() {},
			success( response ) {
				$( document.body ).trigger( 'update_checkout' );
			},
			complete() {},
		} );
	} );
} );
