export default class QuantityTable {
	constructor( element ) {
		this.elementDOM = element;
		this.table = false;
		this.productDOM = this.elementDOM.parents(
			ywdpd_qty_args.column_product_info_class
		);
		this.qtyDOM = this.productDOM.find(
			ywdpd_qty_args.product_qty_classes
		);
		this.variationForm = this.productDOM.find(
			ywdpd_qty_args.variation_form_class
		);
		this.qtyDOM.on( 'change', this.handleChangeQuantity.bind( this ) );
		this.initTable();
		this.defaultTable = this.elementDOM.html();
		if ( this.variationForm ) {
			this.variationForm.on(
				'show_variation',
				this.showVariationTable.bind( this )
			);
			this.variationForm.on(
				'reset_data',
				this.resetVariationTable.bind( this )
			);
		}
	}

	initTable() {
		this.table = this.elementDOM.find( '#ywdpd-quantity-table' );
		this.table.on( 'click', 'td', this.handleTdClickEvent.bind( this ) );
		this.selectDefaultQty();
	}

	handleTdClickEvent( event ) {
		event.preventDefault();
		let td = jQuery( event.target );
		this.selectRightInfo( td );
	}

	selectDefaultQty() {
		if ( 'yes' === ywdpd_qty_args.is_default_qty_enabled ) {
			let td;

			if ( 'yes' === ywdpd_qty_args.show_minimum_price ) {
				td = this.table.find( 'td.qty-price-info' ).last();
			} else {
				td = this.table.find( 'td.qty-price-info' ).first();
			}
			td.trigger( 'click' );
		}
	}

	selectRightInfo( td, updateQty = true ) {
		if ( this.table.hasClass( 'vertical' ) ) {
			let tr = td.parents( 'tr' );
			this.toggleRow( tr, updateQty );
		} else {
			let index = td.index();
			this.toggleColumn( index, updateQty );
		}
	}

	toggleRow( tr, updateQty ) {
		this.table.find( 'td' ).removeClass( 'ywdpd_active' );
		tr.find( 'td' ).addClass( 'ywdpd_active' );
		let tdQty = tr.find( 'td.qty-info' );
		if ( updateQty ) {
			this.updateQuantityField( tdQty );
		}

		this.updatePriceField( tdQty );
	}

	toggleColumn( columnIndex, updateQty ) {
		this.table.find( 'td' ).removeClass( 'ywdpd_active' );
		this.table
			.find( 'tr td:nth-of-type(' + columnIndex + ')' )
			.addClass( 'ywdpd_active' );
		let td = this.table.find(
			'tr td.qty-info:nth-of-type(' + columnIndex + ')'
		);
		if ( updateQty ) {
			this.updateQuantityField( td );
		}
		this.updatePriceField( td );
	}

	handleChangeQuantity( event ) {
		event.stopImmediatePropagation();
		let qty = parseInt( event.target.value );
		if ( 'yes' === ywdpd_qty_args.is_change_qty_enabled && qty > 0 ) {
			let td = this.table.find( 'td.qty-info' ).filter( function () {
				let max = jQuery( this ).data( 'qtymax' );
				if ( max !== '*' ) {
					return (
						jQuery( this ).data( 'qtymin' ) <= qty &&
						jQuery( this ).data( 'qtymax' ) >= qty
					);
				}
				return jQuery( this ).data( 'qtymin' ) <= qty;
			} );

			if ( td ) {
				this.selectRightInfo( td, false );
			}
		}
	}

	updateQuantityField( tdQuantity ) {
		let newQty =
			'*' !== tdQuantity.data( 'qtymax' ) &&
			! ywdpd_qty_args.select_minimum_quantity
				? tdQuantity.data( 'qtymax' )
				: tdQuantity.data( 'qtymin' );
		this.qtyDOM.val( newQty );
	}

	updatePriceField( tdQuantity ) {
		let productType = this.table.data( 'product_type' );

		if ( 'variable' !== productType ) {
			let newPrice = '';
			if ( tdQuantity.length ) {
				newPrice = tdQuantity.data( 'formatted_price' );
			} else {
				newPrice = this.table.data( 'price_html' );
			}
			let rawPrice = tdQuantity.data( 'unformatted_price' );

			this.updatePriceHtml( newPrice, rawPrice );
		}
	}

	updatePriceHtml( newPrice, rawPrice ) {
		let extra_class = '';
		if ( '' !== newPrice ) {
			if (
				this.productDOM.parents( '.yith-quick-view-content' ).length
			) {
				extra_class = '.yith-quick-view-content ';
			}
			let product_container = jQuery(
				extra_class + ywdpd_qty_args.column_product_info_class
			);
			if (
				! product_container.find( '.woocommerce-variation-price' )
					.length
			) {
				product_container
					.find( ywdpd_qty_args.product_price_classes )
					.html( newPrice );
			} else {
				let variations_price = product_container.find(
					'.woocommerce-variation-price .price'
				);

				variations_price.html( newPrice );
			}
			jQuery( document ).trigger( 'ywdpd_price_html_updated', [
				newPrice,
				rawPrice,
			] );
		}
	}

	showVariationTable( event, variation ) {
		if ( variation.table_price ) {
			let variationTableWrapper = jQuery( variation.table_price );
			if (
				variationTableWrapper.find( '#ywdpd-quantity-table' ).length
			) {
				this.elementDOM.html( variationTableWrapper.html() );
				this.initTable();
			} else {
				this.updatePriceHtml( variation.price_html, '' );
			}
		}
	}

	resetVariationTable() {
		if ( 'yes' === ywdpd_qty_args.show_variable_table ) {
			this.elementDOM.html( this.defaultTable );
			this.initTable();
		} else {
			this.elementDOM.hide();
		}
	}
}
